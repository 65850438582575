import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    minHeight: "24.375rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "2rem 2rem 3rem",
      height: "100vh",
    },
  },
  containerList: {
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "2.03rem",
    width: "38.2rem",
    margin: "0 auto",
    [theme.breakpoints.down(1240)]: {
      width: 280,
    },
    "& ul": {
      lineHeight: 0.75,
      margin: "3rem auto",
      [theme.breakpoints.down(1240)]: {
        lineHeight: 1,
      },
    },
    "& li": {
      listStyle: "inside",
    },
  },
  containerButtons: {
    justifyContent: "center",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      margin: "5rem auto 0",
      alignItems: "center",
    },
  },
}));
export default useStyles;
