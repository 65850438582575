import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./House.style";
import HeaderServices from "@components/Services/Layout/Header/Header";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import useGeneralStyles from "@components/Services/styles";
import { DEPARTMENT_ICON, HOUSE_ICON } from "images/GCP";
import TypeHouse from "./House/TypeHouse";
import TypeDepartament from "./Departament/TypeDepartament";

interface HouseProps {
  className?: string;
  setStep: () => void;
  setFinishSave: (v: any) => void;
  onSend: () => void;
}
const SecurityTypeHouse = ({
  className,
  setStep,
  setFinishSave,
  onSend,
}: HouseProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [click, setClick] = useState(-1);

  let StepSearchingSelect;

  switch (click) {
    case -1:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Hogar"]}
            onBackClick={() => {
              setStep();
            }}
          />
          <div className={classes.container}>
            <div className={classesGeneral.principalTitle}>
              Tipo de vivienda
            </div>
            <div className="divFlex">
              <ServiceButton
                name={
                  <span className="containertxt">
                    <img src={HOUSE_ICON} alt="" /> <span>Casa</span>
                  </span>
                }
                onClick={() => {
                  setClick(0);
                }}
              />
              <ServiceButton
                name={
                  <span className="containertxt">
                    <img src={DEPARTMENT_ICON} alt="" />{" "}
                    <span>Departamento</span>
                  </span>
                }
                onClick={() => setClick(1)}
              />
            </div>
          </div>
        </>
      );
      break;
    case 0:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Hogar", "Casa"]}
            onBackClick={() => {
              setClick(-1);
            }}
          />
          <TypeHouse
            onSend={onSend}
            setFinishSave={setFinishSave}
            setStep={setStep}
          />
        </>
      );
      break;
    case 1:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Hogar", "Departamento"]}
            onBackClick={() => {
              setClick(-1);
            }}
          />
          <TypeDepartament
            onSend={onSend}
            setFinishSave={setFinishSave}
            setStep={setStep}
          />
        </>
      );
      break;
  }
  return <>{StepSearchingSelect}</>;
};
export default SecurityTypeHouse;
