import React, { useState } from "react";
import useGeneralStyles from "@components/Services/styles";
import useStyles from "./travel.styles";
import { TextInput } from "@components/Inputs";
import { ButtonPrimary } from "@components/Reusables";
import { useForm } from "@hooks";
import { TypeDestine } from "@components/Inputs/SingleSelectors/Services/TypeDestine";

interface travelProps {
  onSend: () => void;
  setFinishSave: (v: any) => void;
}

const Travel = ({ onSend, setFinishSave }: travelProps) => {
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [form] = useForm();
  const [destination, setDestination] = useState("");
  const [origin, setOrigin] = useState("");
  const [adults, setAdults] = useState("");
  const [children, setChildren] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [date, setDate] = useState("");

  const disabled = !(
    destination &&
    origin &&
    adults &&
    children &&
    returnDate &&
    date
  );

  return (
    <div className={classes.container}>
      <div className={classesGeneral.secondary}>
        Para realizar el análisis, cuéntanos...
      </div>
      <div className={classes.containerFlex}>
        <div>
          <span className={classesGeneral.textLabel}>Destino</span>
          <TypeDestine
            className="inputLarge"
            type={"text"}
            onChange={(e) => {
              setDestination(e.target.value as string);
            }}
            value={destination}
          />
        </div>
        <div>
          <span className={classesGeneral.textLabel}>Salida</span>
          <TextInput
            className="inputLarge"
            type={"date"}
            onChange={(e) => {
              setOrigin(e.target.value as string);
            }}
            value={origin}
          />
        </div>
        <div>
          <span className={classesGeneral.textLabel}>Regreso</span>
          <TextInput
            className="inputLarge"
            type={"date"}
            onChange={(e) => {
              setReturnDate(e.target.value as string);
            }}
            value={returnDate}
          />
        </div>
      </div>
      <div className={classes.containerFlex}>
        <div className={classes.flexTrip2}>
          <span className={classesGeneral.textLabel}>N° de adultos</span>
          <TextInput
            className="inputShort"
            type={"number"}
            onChange={(e) => {
              setAdults(e.target.value as string);
            }}
            value={adults}
          />
        </div>
        <div>
          <span className={classesGeneral.textLabel}>N° de niños</span>
          <TextInput
            className="inputShort"
            type={"number"}
            onChange={(e) => {
              setChildren(e.target.value as string);
            }}
            value={children}
          />
        </div>
        <div>
          <span className={classesGeneral.textLabel}>Edades</span>
          <TextInput
            className="inputLarge"
            type={"number"}
            onChange={(e) => {
              setDate(e.target.value as string);
            }}
            value={date}
          />
        </div>
      </div>
      <div className={`${classesGeneral.containerButtons} ${classes.end}`}>
        <ButtonPrimary
          disabled={disabled}
          noDegraded
          text="Enviar"
          onClick={() => {
            form.sure = {
              ...form.sure,
              Destino: destination,
              Salida: origin,
              Regreso: returnDate,
              "Nro de adultos": adults,
              "Nro de niños": children,
              Edades: date,
            };
            setFinishSave({
              Notification2448: true,
              BallCharge: false,
            });

            onSend();
          }}
        />
      </div>
    </div>
  );
};
export default Travel;
