import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./FormPet.style";
import useGeneralStyles from "@components/Services/styles";
import { GenderSelector, TextInput } from "@components/Inputs";
import { YesOrNoSelector } from "@components/Inputs/SingleSelectors/Services/YesOrNo";
import { ButtonPrimary } from "@components/Reusables";
import { BACK_ICON } from "images/GCP";
import { useForm } from "@hooks";
import { GenderPet } from "@components/Inputs/SingleSelectors/Services/GenderPet";

interface ResponsiblePetProps {
  className?: string;
  setStep: (value: number) => void;
  onClick: () => void;
  setFinishSave: (v: any) => void;
  onSend: () => void;
}
const FormPet = ({
  className,
  setStep,
  onClick,
  setFinishSave,
  onSend,
}: ResponsiblePetProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [form] = useForm({});

  const [edad, setEdad] = useState<string>();
  const [sexo, setSexo] = useState<string>();
  const [raza, setRaza] = useState<string>();
  const [chip, setChip] = useState<string>();

  const enabled = !(edad && sexo && raza && chip);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.containerInputs}>
          <div className={classes.containergroup}>
            <span className={classes.input}>Edad de tu mascota</span>
            <TextInput
              type={"number"}
              name="numeric"
              className={classes.selectorMinimized}
              onChange={(e) => {
                setEdad(e.target.value as string);
              }}
              error={!edad}
              value={edad}
            />
          </div>
          <div className={classes.containergroup}>
            <span className={classes.input}>Sexo</span>
            <GenderPet
              className={classes.selectorMinimized}
              onChange={(e) => {
                setSexo(e.target.value as string);
              }}
              error={!sexo}
              value={sexo}
            />
          </div>
          <div className={classes.containergroup}>
            <span className={classes.input}>Raza</span>
            <TextInput
              type={"text"}
              name="numeric"
              className={classes.selectorMinimized}
              onChange={(e) => {
                setRaza(e.target.value as string);
              }}
              error={!raza}
              value={raza}
            />
          </div>
          <div className={classes.containergroup}>
            <span className={classes.input}>¿Tiene chip?</span>
            <YesOrNoSelector
              className={classes.selectorMinimized}
              onChange={(e) => {
                setChip(e.target.value as string);
              }}
              error={!chip}
              value={chip}
            />
          </div>
          {isMobile && (
            <div className={classes.positionArrow}>
              <img
                onClick={() => {
                  if (!enabled) {
                    form.sure = {
                      ...form.sure,
                      "Edad mascota": edad,
                      Sexo: sexo,
                      Raza: raza,
                      "Tiene Chip?": chip,
                    };
                    onClick();
                    onSend();
                    setFinishSave({
                      Notification2448: true,
                      BallCharge: false,
                    });
                  }
                }}
                src={BACK_ICON}
              />
            </div>
          )}
        </div>
      </div>
      {!isMobile && (
        <div className={classes.positionArrow}>
          <img
            onClick={() => {
              if (!enabled) {
                form.sure = {
                  ...form.sure,
                  "Edad mascota": edad,
                  Sexo: sexo,
                  Raza: raza,
                  "Tiene Chip?": chip,
                };
                onSend();
                onClick();
                setFinishSave({
                  Notification2448: true,
                  BallCharge: false,
                });
              }
            }}
            src={BACK_ICON}
          />
        </div>
      )}
    </>
  );
};
export default FormPet;
