import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./TypeSecurity.style";
import HeaderServices from "@components/Services/Layout/Header/Header";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import useGeneralStyles from "@components/Services/styles";
import BasicHealth from "../Basic/Basic";
import ComplementaryHealth from "../Complementary/Complementary";
import BasicHealth2 from "../Basic/Basic2";
import { useForm } from "@hooks";
interface YouSeekProps {
  className?: string;
  setStepContinue: (value: number) => void;
  onSend: () => void;
  setFinishSave: (v: any) => void;
}
const TypeSecurityHealth = ({
  className,
  setStepContinue,
  onSend,
  setFinishSave,
}: YouSeekProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [step, setStep] = useState(-1);
  const [form] = useForm({});

  let StepSearchingSelect;

  switch (step) {
    case -1:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Salud"]}
            onBackClick={() => {
              setStepContinue(-1);
            }}
          />
          <div className={`${className || ""} ${classes.container}`}>
            <div className={classes.title}>Tipo de seguro</div>
            <section className={classes.containerList}>
              {List.map((item) => (
                <ServiceButton
                  onClick={() => {
                    setStep(item.id);
                    form.sure = {
                      ...form.sure,
                      "tipo de seguro": item.name,
                    };
                  }}
                  key={item.id}
                  name={item.name}
                  title={item.title}
                />
              ))}
            </section>
            <div
              className={`${classesGeneral.title} ${classes.centerLastText}`}
            >
              Es importante protegerse ante diferentes situaciones e imprevistos
              de salud.{" "}
            </div>
          </div>{" "}
        </>
      );
      break;
    case 0:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Salud", "Básico"]}
            onBackClick={() => {
              setStep(-1);
            }}
          />
          <BasicHealth
            onContinue={() => {
              setStep(1);
            }}
          />
        </>
      );
      break;
    case 1:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Salud", "Básico"]}
            onBackClick={() => {
              setStep(0);
            }}
          />
          <BasicHealth2 setFinishSave={setFinishSave} onSend={onSend} />
        </>
      );
      break;
    case 2:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Salud", "Complementario"]}
            onBackClick={() => {
              setStep(-1);
            }}
          />
          <ComplementaryHealth
            onClick={() => {
              form.sure = {
                ...form.sure,
                "Tipo de seguro": "Complementario",
              };
            }}
            setFinishSave={setFinishSave}
            onSend={onSend}
          />
        </>
      );
      break;
    case 3:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Salud", "Dental"]}
            onBackClick={() => {
              setStep(-1);
            }}
          />
          <ComplementaryHealth
            onClick={() => {
              form.sure = {
                ...form.sure,
                "Tipo de seguro": "Dental",
              };
            }}
            setFinishSave={setFinishSave}
            onSend={onSend}
          />{" "}
        </>
      );
      break;
  }
  return <>{StepSearchingSelect}</>;
};
export default TypeSecurityHealth;

const List = [
  { id: 0, name: "Isapre o Fonasa", title: "Básico" },
  { id: 2, name: "Descripción", title: "Complementario" },
  { id: 3, name: "Descripción", title: "Dental" },
];
