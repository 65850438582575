import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./TypeHouse.style";
import { DestinationSelector } from "@components/Inputs/SingleSelectors/Services/Destination";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import { YesOrNoSelector } from "@components/Inputs/SingleSelectors/Services/YesOrNo";
import useGeneralStyles from "@components/Services/styles";
import { useForm } from "@hooks";
import { TypeStructure } from "@components/Inputs/SingleSelectors/Services/TypeStructure";
import { TypeAntiquity } from "@components/Inputs/SingleSelectors/Services/TypeAntiquity";

interface TypeHouseProps {
  className?: string;
  setStep: (value: number) => void;
  setFinishSave: (v: any) => void;
  onSend: () => void;
}
const TypeHouse = ({
  className,
  setStep,
  setFinishSave,
  onSend,
}: TypeHouseProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();

  const [estructura, setEsctructura] = useState<string>();
  const [condominio, setCondominio] = useState<string>();
  const [nroCasa, setNroCasa] = useState<string>();
  const [direccion, setDireccion] = useState<string>();
  const [antiguedad, setAntiguedad] = useState<string>();

  const [form] = useForm();

  const enabled = !(
    estructura &&
    condominio &&
    nroCasa &&
    direccion &&
    antiguedad
  );

  return (
    <div className={classes.container}>
      <div className={classes.containerInputs}>
        <div className={classes.containergroup}>
          <span className={classes.input}>Tipo de estructura</span>
          <TypeStructure
            className={classes.selector}
            onChange={(e) => {
              setEsctructura(e.target.value as string);
            }}
            value={estructura}
          />
        </div>
        <div className={classes.containergroup}>
          <span className={classes.input}>¿Se encuentra en condominio?</span>
          <YesOrNoSelector
            className={classes.selector}
            onChange={(e) => {
              setCondominio(e.target.value as string);
            }}
            value={condominio}
          />
        </div>
        <div className={classes.containergroup}>
          <span className={classes.input}>N° de la casa</span>
          <TextInput
            type={"number"}
            name="numeric"
            className={classes.selector}
            onChange={(e) => {
              setNroCasa(e.target.value as string);
            }}
            value={nroCasa}
          />
        </div>
        <div className={classes.containergroup}>
          <span className={classes.input}>Dirección</span>
          <TextInput
            className={classes.selector}
            onChange={(e) => {
              setDireccion(e.target.value as string);
            }}
            value={direccion}
          />
        </div>
        <div className={classes.containergroup}>
          <span className={classes.input}>Antigüedad</span>
          <TypeAntiquity
            className={classes.selector}
            onChange={(e) => {
              setAntiguedad(e.target.value as string);
            }}
            value={antiguedad}
          />
        </div>
      </div>
      <div
        className={`${classesGeneral.containerButtons} ${classes.containerButtons}`}
      >
        <ButtonPrimary
          disabled={enabled}
          text="Enviar"
          onClick={() => {
            form.sure = {
              ...form.sure,
              "Tipo de estructura": estructura,
              "Se encuentra en condominio?": condominio,
              Dirección: direccion,
              Antigüedad: antiguedad,
              "Nro de casa": nroCasa,
            };
            setFinishSave({
              Notification2448: false,
              BallCharge: true,
            });
            onSend();
          }}
          noDegraded
        />
      </div>
    </div>
  );
};
export default TypeHouse;
