import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./SouthBridge.styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import useGeneralStyles from "@components/Services/styles";
import { SOUTHBRIDGE_ICON } from "images/GCP";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { useForm } from "@hooks";
import ForecastAndDate from "@components/Services/ForecastAndDate/ForecastAndDate";

interface ResponsiblePetProps {
  className?: string;
  topTitle?: string;
  setStepContinue: () => void;
  setFinishSave: (v: any) => void;
  onSend: () => void;
}

const SouthBridge = ({
  className,
  topTitle,
  setStepContinue,
  setFinishSave,
  onSend,
}: ResponsiblePetProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [form] = useForm({});
  const [step, setStep] = useState(-1);
  let Component;
  switch (step) {
    case -1:
      Component = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Oncológico"]}
            onBackClick={() => {
              setStepContinue();
            }}
          />
          <div className={classes.container}>
            <div className={"containerFlex"}>
              <div>
                {topTitle && !isMobile && (
                  <div className={`${classesGeneral.secondary} secondary`}>
                    {topTitle}
                  </div>
                )}
                <div className={classesGeneral.principalTitle}>
                  Tipo de seguro
                </div>
                <div className={classes.containerButton}>
                  <ServiceButton
                    onClick={() => {
                      form.sure = {
                        ...form.sure,
                        "Tipo de seguro":
                          "Seguro con descuento, Convenio con SouthBridge",
                      };
                      setFinishSave({
                        Notification2448: false,
                        BallCharge: true,
                      });
                      onSend();
                    }}
                    name="Convenio con SouthBridge"
                    title="Seguro con descuento"
                  />
                  <ServiceButton
                    onClick={() => {
                      form.sure = {
                        ...form.sure,
                        "Tipo de seguro": "Análisis personalizado",
                      };
                      setStep(0);
                    }}
                    name="Buscaremos alternativas según tus necesidades"
                    title="Análisis personalizado"
                  />
                </div>
              </div>

              <div className="RightBlock">
                {isMobile && (
                  <div className={classesGeneral.principalTitle}>
                    Mascota responsable{" "}
                  </div>
                )}
                <img src={SOUTHBRIDGE_ICON} />
                <div className={classes.parrafo}>
                  Tenemos un convenio con <strong>SouthBridge</strong> para una
                  tarifa preferencial. Sino, puedes pedir un análisis de
                  diferentes alternativas.
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 0:
      Component = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Oncológico"]}
            onBackClick={() => {
              setStepContinue();
            }}
          />
          <ForecastAndDate setFinishSave={setFinishSave} onSend={onSend} />
        </>
      );
      break;
    default:
      break;
  }

  return <>{Component}</>;
};
export default SouthBridge;
