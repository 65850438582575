import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./Basic.styles";
import useGeneralStyles from "@components/Services/styles";

import { DestinationSelector } from "@components/Inputs/SingleSelectors/Services/Destination";
import { ButtonPrimary, NumericInput } from "@components/Reusables";
import { GenderSelector, TextInput } from "@components/Inputs";
import { useForm } from "@hooks";
import { MinimumCovered } from "@components/Inputs/SingleSelectors/Services/MinimumCovered";
import { Forecast } from "@components/Inputs/SingleSelectors/Services/Forecast";
import { GenderPet } from "@components/Inputs/SingleSelectors/Services/GenderPet";
interface YouSeekProps {
  className?: string;
  onSend: () => void;
  setFinishSave: (v: any) => void;
}
const BasicHealth2 = ({ className, onSend, setFinishSave }: YouSeekProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [form] = useForm({});

  const [minimoCubierto, setMinimoCubierto] = useState<string>();
  const [minimoCubierto2, setMinimoCubierto2] = useState<string>();
  const [clinicaPreferencia, setClinicaPreferencia] = useState<string>();
  const [edad, setEdad] = useState<string>();
  const [sexo, setSexo] = useState<string>();
  const [sueldo, setSueldo] = useState<string>();

  const enabled = !(
    sueldo &&
    sexo &&
    edad &&
    minimoCubierto &&
    minimoCubierto2
  );

  return (
    <>
      <div className={`${classes.containerSecondary} `}>
        <div className={`${classes.flex} `}>
          <div className={classes.firstPart}>
            <div className={classesGeneral.secondary}>
              En los planes tienes la posibilidad de <span>elegir:</span>
            </div>
            <ul className={classes.containerList}>
              <li className={classesGeneral.secondary}>
                <span>Clínicas de preferencia</span>
              </li>
              <li className={classesGeneral.secondary}>
                <span>Cobertura Ambulatoria </span>
                (consultas, exámenes, procedimientos, otros)
              </li>
              <li className={classesGeneral.secondary}>
                <span>Cobertura Hospitalario </span>(día <br /> cama, cirugía
                mayor, otros)
              </li>
            </ul>
          </div>
          <div>
            <div className={classesGeneral.secondary}>
              La Cobertura Ambulatoria puede ir <br /> entre 60% a 100%.
            </div>
            <div className={classes.containerInput}>
              <div className={classesGeneral.textLabel}>
                Mínimo que te gustaría estar cubierto
              </div>
              <MinimumCovered
                className={classes.selector}
                onChange={(e) => {
                  setMinimoCubierto(e.target.value as string);
                }}
                value={minimoCubierto}
              />
            </div>{" "}
            <div className={classes.containerInput}>
              <div className={classesGeneral.textLabel}>
                Clínica de preferencia (opcional)
              </div>
              <TextInput
                label="Nombre clínica"
                className={classes.selector}
                onChange={(e) => {
                  setClinicaPreferencia(e.target.value as string);
                }}
                value={clinicaPreferencia}
              />
            </div>{" "}
            <div className={classes.containerInput}>
              <div className={classesGeneral.textLabel}>Sueldo líquido</div>
              <NumericInput
                name="sal"
                /*                   className={classes.selector}
                 */ onChange={(e) => {
                  setSueldo(e.target.value as string);
                }}
                value={+sueldo || 0}
              />
            </div>
          </div>
          <div>
            <div className={classesGeneral.secondary}>
              La Cobertura Hospitalaria puede ir <br /> entre 60% a 100%.
            </div>
            <div className={classes.containerInput}>
              <div className={classesGeneral.textLabel}>
                Mínimo que te gustaría estar cubierto
              </div>
              <MinimumCovered
                className={classes.selector}
                onChange={(e) => {
                  setMinimoCubierto2(e.target.value as string);
                }}
                value={minimoCubierto2}
              />
            </div>
            <div className={classes.containerInputFlex}>
              <div>
                <div className={classesGeneral.textLabel}>Tu edad</div>
                <TextInput
                  type={"number"}
                  className={"selector"}
                  onChange={(e) => {
                    setEdad(e.target.value as string);
                  }}
                  value={edad}
                />
              </div>{" "}
              <div>
                <div className={classesGeneral.textLabel}>Tu sexo</div>
                <GenderPet
                  className={"selector"}
                  onChange={(e) => {
                    setSexo(e.target.value as string);
                  }}
                  value={sexo}
                />
              </div>
            </div>
          </div>
        </div>{" "}
        <div className={`${classesGeneral.containerButtons} ${classes.end}`}>
          <ButtonPrimary
            noDegraded
            disabled={enabled}
            text="Enviar"
            onClick={() => {
              form.sure = {
                ...form.sure,
                "Mínimo que te gustaría estar cubierto (Ambulatoria)":
                  minimoCubierto,
                "Mínimo que te gustaría estar cubierto (Hospitalaria)":
                  minimoCubierto2,
                "Clínica de preferencia": clinicaPreferencia || "No definido",
                "Sueldo líquido": sueldo,
                "Tu edad": edad,
                "Tu sexo": sexo,
              };
              setFinishSave({ Notification2448: true, BallCharge: false });
              onSend();
            }}
          />
        </div>
      </div>{" "}
    </>
  );
};
export default BasicHealth2;
