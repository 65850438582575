import React from "react";
import { CommonSelectorProps, Select } from "@components";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}
const OPTIONS = ["Liviana", "Mixta", "Solida"].map((r) => ({
  name: r,
  value: r,
}));
export const TypeStructure: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-gender-selector`}
      name={name || "reazonContact"}
      label={label || "Tipo de estructura"}
      options={OPTIONS}
      {...props}
    />
  );
};
