import React, { useCallback, useState } from "react";
import Categories from "@components/Services/Categories/Accident";
import LayoutServices from "@components/Services/Layout/LayoutServices";
import YouSeek from "@components/Services/YouSeek/YouSeek";
import { SEO } from "@components/Structural";
import { useAuth, useForm } from "@hooks";
import { useMediaQuery } from "@material-ui/core";
import Automotive from "@components/Services/Categories/Automotive/Automotive";
import Sport from "@components/Services/Categories/Sport/Sport";
import TypeSecurity from "@components/Services/Security/Home/TypeSecurity/TypeSecurity";
import TypeSecurityPets from "@components/Services/Security/Pets/TypeSecurity/TypeSecurity";
import SouthBridge from "@components/Services/Security/SouthBridge/SouthBridge";
import TypeSecurityHealth from "@components/Services/Security/Health/TypeSecurity/TypeSecurity";
import AssistCard from "@components/Services/Security/AssistCard/AssistCard";
import Life from "@components/Services/Security/Life/Life";
import BallCharge from "@components/Services/Notification/BallCharge";
import Notification2448 from "@components/Services/Notification/Notification2448";
import { useCurrentUser } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";
const security = () => {
  useAuth({ redirectIfNotLogged: true });

  //accidentes, automotriz atc..
  const isMobile = useMediaQuery("(max-width: 1200px)");

  const [stepContinue, setStepContinue] = useState(-1); //-1
  const [finishSave, setFinishSave] = useState({
    Notification2448: false || undefined,
    BallCharge: false || undefined,
  });

  const { user: currentUser } = useCurrentUser();
  const client = useApolloClient();
  const [form] = useForm({});

  const onSend = useCallback(() => {
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.sure;
    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {});
  }, [currentUser?.name, form]);

  let StepSearchingSelect;

  switch (stepContinue) {
    case -1:
      StepSearchingSelect = <YouSeek setStepContinue={setStepContinue} />;
      break;
    case 0:
      StepSearchingSelect = (
        <Categories
          setStepContinue={() => setStepContinue(-1)}
          setFinishSave={setFinishSave}
          onSend={onSend}
        />
      );
      break;
    case 1:
      StepSearchingSelect = (
        <Automotive
          setFinishSave={setFinishSave}
          setStepContinue={() => setStepContinue(-1)}
          onSend={onSend}
        />
      );
      break;
    case 2:
      StepSearchingSelect = (
        <Sport
          setStepContinue={() => setStepContinue(-1)}
          setFinishSave={setFinishSave}
          onSend={onSend}
        />
      );
      break;
    case 3:
      StepSearchingSelect = (
        <TypeSecurity
          setFinishSave={setFinishSave}
          setStepContinue={() => setStepContinue(-1)}
          onSend={onSend}
        />
      );
      break;
    case 4:
      StepSearchingSelect = (
        <TypeSecurityPets
          setFinishSave={setFinishSave}
          onSend={onSend}
          setStepContinue={() => setStepContinue(-1)}
        />
      );
      break;
    case 5:
      StepSearchingSelect = (
        <SouthBridge
          setFinishSave={setFinishSave}
          onSend={onSend}
          setStepContinue={() => setStepContinue(-1)}
          topTitle="¡Es muy bueno protegerse ante estas situaciones!"
        />
      );
      break;
    case 6:
      StepSearchingSelect = (
        <TypeSecurityHealth
          onSend={onSend}
          setFinishSave={setFinishSave}
          setStepContinue={() => setStepContinue(-1)}
        />
      );
      break;
    case 7:
      StepSearchingSelect = (
        <AssistCard
          onSend={onSend}
          setFinishSave={setFinishSave}
          setStepContinue={() => setStepContinue(-1)}
        />
      );
      break;
    case 8:
      StepSearchingSelect = (
        <Life
          onSend={onSend}
          setFinishSave={setFinishSave}
          setStepContinue={() => setStepContinue(-1)}
        />
      );
      break;
  }

  return (
    <>
      <SEO />
      {finishSave.Notification2448 && <Notification2448 />}
      {finishSave.BallCharge && <BallCharge />}
      {!finishSave.Notification2448 && !finishSave.BallCharge && (
        <LayoutServices
          title={"CRÉDITOS"}
          key="Bottom"
          onBackClick={() => {
            setStepContinue(stepContinue - 1);
          }}
          activeServices={true}
          hiddenHeader={!isMobile}
        >
          {StepSearchingSelect}
        </LayoutServices>
      )}
    </>
  );
};

export default security;
