import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./AssistCard.styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import useGeneralStyles from "@components/Services/styles";
import { ASSIST_CARD_ICON } from "images/GCP";
import HeaderServices from "@components/Services/Layout/Header/Header";
import Travel from "../Travel/Travel";
import { useForm } from "@hooks";

interface ResponsiblePetProps {
  className?: string;
  topTitle?: string;
  setStepContinue: () => void;
  onSend: () => void;
  setFinishSave: (v: any) => void;
}

const AssistCard = ({
  className,
  topTitle,
  setStepContinue,
  onSend,
  setFinishSave,
}: ResponsiblePetProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [step, setStep] = useState(false);
  const [form] = useForm({});

  return (
    <>
      {!step && (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Viaje"]}
            onBackClick={() => {
              !step ? setStepContinue() : setStep(false);
            }}
          />
          <div className={classes.container}>
            <div className={"containerFlex"}>
              <div>
                {topTitle && (
                  <div className={classesGeneral.secondary}>{topTitle}</div>
                )}
                <div className={classesGeneral.principalTitle}>
                  Tipo de seguro
                </div>
                <div className={classes.containerButton}>
                  <ServiceButton
                    onClick={() => {
                      form.sure = {
                        ...form.sure,
                        "tipo de seguro":
                          "Seguro con descuento, Convenio con AssistCard",
                      };
                      setFinishSave({
                        Notification2448: true,
                        BallCharge: false,
                      });
                      onSend();
                    }}
                    name="Convenio con AssistCard"
                    title="Seguro con descuento"
                  />
                  <ServiceButton
                    onClick={() => {
                      setStep(true);
                      form.sure = {
                        ...form.sure,
                        "tipo de seguro": "Análisis personalizado",
                      };
                    }}
                    name="Buscaremos alternativas según tus necesidades"
                    title="Análisis personalizado"
                  />
                  {!isMobile && (
                    <div className={classesGeneral.secondary}>
                      Si vamos a viajar, es bueno estar <br /> protegidos ante
                      imprevistos
                    </div>
                  )}
                </div>
              </div>
              <div className="RightBlock">
                {isMobile && (
                  <div className={classes.parrafo}>
                    ¡Los más regalones de la casa también deben estar
                    protegidos!
                  </div>
                )}
                <img src={ASSIST_CARD_ICON} />
                <div className={classes.parrafo}>
                  Tenemos un convenio con <strong>AssistCard</strong> para una
                  tarifa preferencial. Sino, puedes pedir un análisis de
                  diferentes alternativas.
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step && (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Viaje"]}
            onBackClick={() => {
              setStepContinue();
            }}
          />
          <Travel onSend={onSend} setFinishSave={setFinishSave} />
        </>
      )}
    </>
  );
};
export default AssistCard;
