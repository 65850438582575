import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "67rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "3rem 4rem 5rem",
    [theme.breakpoints.down(1300)]: {
      margin: "2rem auto 0",
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    "& .containerFlex": {
      display: "flex",
      gap: "8rem",
    },
    "& .containertxt": {
      display: "flex",
      gap: "1rem",
      alignItems: "inherit",
      "& span": {
        marginTop: 3,
      },
    },
    "& .divFlex": {
      display: "flex",
      gap: "3.5rem",
    },
  },
  input: {
    display: "flex",
    flexDirection: "column",
    color: "#6b6b6b",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "0.75rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: "8.75rem",
      margin: "0 auto",
    },
  },
  containerInputs: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3rem",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: "1rem",
    },
  },
  containerButtons: {
    justifyContent: "end",
    marginTop: "2rem",
    [theme.breakpoints.down(1300)]: {
      justifyContent: "center",
    },
  },
  selectorMinimized: {
    width: "8.75rem",
    [theme.breakpoints.down(1300)]: {
      margin: "0 auto",
      display: "flex",
    },
  },
  positionArrow: {
    cursor: "pointer",
    width: "67rem",
    margin: "0rem auto",
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down(1300)]: {
      margin: "2rem auto",
      width: 120,
      display: "flex",
    },
  },
}));
export default useStyles;
