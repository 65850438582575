import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto 0",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 2rem",
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: "100%",
      height: "90vh",
      margin: "2rem auto 0",
    },
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 500,
    margin: "0 auto",
    width: "9rem",
  },
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "2.03rem",
  },
  centerEndText: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "3rem",
    justifyContent: "center",
    gap: "2.03rem",
  },
}));
export default useStyles;
