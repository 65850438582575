import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "66rem",
    margin: "3rem auto",
    fontFamily: "Montserrat",
    background: "#F4F3F3",
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    borderRadius: 30,
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: "100%",
      minHeight: "90vh",
      margin: "2rem auto 0",
      justifyContent: "center",
    },
    "& .inputShort": {
      width: "8rem",
    },
    "& .inputLarge": {
      width: "17rem",
    },
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
  },
  containerList: {
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "2.03rem",
    margin: "0 auto",
    "& ul": {
      lineHeight: 0.75,
      margin: "3rem auto",
    },
    "& li": {
      listStyle: "inside",
    },
  },
  containerSecondary: {
    gap: "2.5rem",
    background: "#F4F3F3",
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    borderRadius: 30,
    width: "70rem",
    margin: "3rem auto",
    fontFamily: "Montserrat",
  },
  containerFlex: {
    display: "flex",
    gap: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: 270,
      margin: "0 auto",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  centerLastText: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
    gap: "2.03rem",
  },
  RadioGroup: {
    "& >div": {
      display: "flex",
      flexDirection: "row",
      gap: "5rem",
    },
  },
  styleSpace: {
    width: "17.8rem",
    maxHeight: "9rem",
    height: "9rem",
    margin: "0 auto",
    border: `1px solid rgba(0, 0, 0, 0.25)`,
    borderRadius: 10,
    padding: "0.5rem",
    color: theme.palette.text.secondary,
  },
  positionArrow: {
    margin: "4rem 0rem 2rem auto",
    cursor: "pointer",
  },
  firstPart: {
    width: "18rem",
  },
  selector: {
    width: "17rem",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  containerInput: {
    margin: "1rem auto",
  },
  containerInputFlex: {
    margin: "1rem auto",
    display: "flex",
    justifyContent: "space-between",
    "& .selector": {
      width: "6rem",
    },
  },
  end: {
    justifyContent: "end",
    marginTop: "2rem",
    [theme.breakpoints.down(1300)]: {
      justifyContent: "center",
    },
  },
}));
export default useStyles;
