import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerItems: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3rem",
  },
  align: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  container: {
    display: "flex",
    fontFamily: "Montserrat",
    background: theme.palette.primary.main,
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    gap: "4rem",
    [theme.breakpoints.down(1270)]: {
      flexDirection: "column",
      gap: "2rem",
    },
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    display: "block",
    color: "white",
    width: "20.5rem",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "1rem",
    fontWeight: 300,
    lineHeight: 1.5,
    display: "block",
    color: "white",
    width: "20.5rem",
    textAlign: "center",
    margin: "3rem 0",
  },
  subtitleSecond: {
    fontSize: "1rem",
    fontWeight: 300,
    lineHeight: 1.5,
    display: "block",
    color: "white",
    width: "20.5rem",
    textAlign: "center",
    marginTop: "3rem",
  },
  email: {
    fontSize: "1rem",
    fontWeight: 500,
    fontFamily: "Nunito",
    lineHeight: 2.2,
    display: "block",
    color: "white",
    width: "20.5rem",
    textAlign: "center",
  },
  buttonPrimary: {
    width: "18rem",
    background: theme.palette.primary.dark,
    margin: "4rem auto 0",
    display: "flex",
    fontWeight: 300,
  },
  img: {
    width: "17.2rem",
    [theme.breakpoints.down(1270)]: {
      width: "11rem",
    },
  },
}));
export default useStyles;
