import React, { useCallback } from "react";
import useStyles from "./Notification24hs.styles";
import { ButtonPrimary } from "@components/Reusables";

import { EXITO_IMAGE } from "images/GoogleCloud";
import { useCurrentUser } from "@apollo";
import { useForm } from "@hooks";
import { navigate } from "@reach/router";
import { Route } from "@interfaces";

interface Notification24Props {
  setNeedCreditSelect?: (value: {
    status: boolean;
    value: number;
    subvalue: number;
  }) => void;
  secondText?: boolean;
}

const Notification2448: React.FC<Notification24Props> = ({
  setNeedCreditSelect,
  secondText,
}) => {
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const [form] = useForm({});

  const handleCompleteData = () => {
    navigate(Route.services);
  };

  return (
    <>
      <section className={classes.container}>
        <div>
          <img className={classes.img} src={EXITO_IMAGE} />
        </div>
        <div>
          <span className={classes.title}>¡perfecto!</span>
          <span className={classes.subtitle}>
            Analizaremos diferentes opciones y te entregaremos las alternativas
            más convenientes a este correo en 24/48 hrs.
          </span>
          <span className={classes.subtitle}>{currentUser?.email}</span>
          <ButtonPrimary
            className={classes.buttonPrimary}
            noDegraded
            onClick={() => {
              handleCompleteData();
            }}
            text="Listo"
          />
        </div>
      </section>
    </>
  );
};
export default Notification2448;
