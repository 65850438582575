import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 6rem 3rem",
    "& >div": {
      width: "40rem",
      margin: "0 auto",
    },
    "& .containerFlex": {
      display: "flex",
      gap: "8rem",
    },
    "& .RightBlock": {
      marginTop: "1rem",
    },
  },
  width: {
    width: "38rem",
    margin: "3rem auto 1rem",
    [theme.breakpoints.down(1300)]: {
      width: "17rem",
      margin: "0 auto",
    },
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  buttonPrimary: {
    width: "14rem",
    height: "4.8rem",
    fontFamily: "Nunito",
    fontSize: "1.2rem",
    fontWeight: 300,
  },
  parrafo: {
    color: "#7d7c7c",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    paddingTop: "1.75rem",
    width: "22rem",
  },
  containerList: {
    display: "flex",
    gap: "2rem 2.5rem",
    flexWrap: "wrap",
  },
  end: {
    marginTop: "2rem",
    justifyContent: "center",
  },
  center: {
    textAlign: "center",
    marginBottom: "2rem",
    [theme.breakpoints.down(1300)]: {
      width: "17rem",
      margin: "0 auto",
    },
  },
  centerButton: {
    justifyContent: "center",
    marginTop: "2rem",
  },
}));
export default useStyles;
