import React from "react";
import { CommonSelectorProps, Select } from "@components";

export const TypeDestine: React.FC<CommonSelectorProps> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-gender-selector`}
      name={name || "reazonContact"}
      label={label || "Destino"}
      options={[
        {
          name: "Asia",
          value: "Asia",
        },
        {
          name: "Caribe",
          value: "Caribe",
        },
        {
          name: "Dentro del pais",
          value: "Dentro del pais",
        },
        {
          name: "Europa",
          value: "Europa",
        },
        {
          name: "Norteamérica",
          value: "Norteamérica",
        },
        {
          name: "Oceanía",
          value: "Oceanía",
        },
        {
          name: "Sudamérica",
          value: "Sudamérica",
        },
      ]}
      {...props}
    />
  );
};
