import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./RentingHousehold.style";
import useGeneralStyles from "@components/Services/styles";
import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";

interface RentingHouseholdProps {
  className?: string;
  setStep: (value: number) => void;
  setFinishSave: (v: any) => void;
  onSend: () => void;
}
const RentingHousehold = ({
  className,
  setStep,
  setFinishSave,
  onSend,
}: RentingHouseholdProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();

  return (
    <div className={classes.container}>
      <div className={classes.containerList}>
        <div className={classesGeneral.secondary}>
          Este seguro te cubre con:
        </div>
        <ul>
          <li className={classesGeneral.secondary}>
            <span>Asistencia remota</span> (Psicólogo, nutricionista, profesor,
            etc)
          </li>
          <li className={classesGeneral.secondary}>
            <span>Incendio</span>
          </li>
          <li className={classesGeneral.secondary}>
            <span>Robo</span>
          </li>
          <li className={classesGeneral.secondary}>
            <span>Asistencia hogar</span>
          </li>
        </ul>
      </div>
      <div
        className={`${classesGeneral.containerButtons} ${classes.containerButtons}`}
      >
        <ButtonPrimary
          text="Me interesa"
          onClick={() => {
            setFinishSave({
              Notification2448: false,
              BallCharge: true,
            });
            onSend();
          }}
          noDegraded
        />
        <ButtonOutlined
          text="Quiero ver otra opción"
          onClick={() => {
            setStep(-1);
          }}
        />
      </div>
    </div>
  );
};
export default RentingHousehold;
