import React from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./FullInsurance.style";
import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import { TEMPORALLY_ICON } from "images/GCP";

interface FullInsuranceProps {
  className?: string;
  setStep: (value: number) => void;
  setFinishSave: (v: any) => void;
  onSend: () => void;
}
const FullInsurance = ({
  className,
  setStep,
  setFinishSave,
  onSend,
}: FullInsuranceProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();

  return (
    <div className={classes.container}>
      <div className="containerFlex">
        <div className={classes.containerList}>
          <div className={classesGeneral.secondary}>
            Este seguro te cubre con:
          </div>
          <ul>
            {list.map((item) => (
              <li key={item.id} className={classesGeneral.secondary}>
                {item.text}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <img src={TEMPORALLY_ICON} />
          <div className={classes.parrafo}>
            Tenemos un convenio con <strong>xxxx</strong> para una tarifa
            preferencial. Sino, puedes pedir un análisis de diferentes
            alternativas.
          </div>
        </div>
      </div>
      <div>
        <div className={classesGeneral.principalTitle}>Tipo de seguro</div>
        <div className={classes.containerButtons}>
          <ServiceButton
            onClick={() => {
              setFinishSave({
                Notification2448: false,
                BallCharge: true,
              });
              onSend();
            }}
            name="Convenio con xxx"
            title="Seguro con descuento"
          />
          <ServiceButton
            onClick={() => {
              setStep(4);
            }}
            name="Buscaremos alternativas según tus necesidades"
            title="Análisis personalizado"
          />
        </div>
      </div>
    </div>
  );
};
export default FullInsurance;

const list = [
  {
    id: 0,
    text: (
      <>
        <span>Robo</span>
      </>
    ),
  },
  {
    id: 1,
    text: (
      <>
        <span>Incendio</span> (interior y estructura)
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <span>Sismo</span>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <span>Accidente trabajadores</span> del hogar
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <span>Asistencia hogar</span> (gásfiter, electricista y más)
      </>
    ),
  },
];
