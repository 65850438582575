import React from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./ResponsabilyPet.style";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import useGeneralStyles from "@components/Services/styles";
import { SOUTHBRIDGE_ICON } from "images/GCP";

interface ResponsiblePetProps {
  className?: string;
  setStep: (value: number) => void;
  onClick: () => void;
}
const ResponsabilyPet = ({
  className,
  setStep,
  onClick,
}: ResponsiblePetProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();

  return (
    <div className={classes.container}>
      <div className={"containerFlex"}>
        <div>
          <div className={classesGeneral.principalTitle}>Tipo de seguro</div>
          <div className={classes.containerButton}>
            <ServiceButton
              onClick={() => {}}
              name="Convenio con xxx"
              title="Seguro con descuento"
            />
            <ServiceButton
              onClick={() => {
                setStep(4);
              }}
              name="Buscaremos alternativas según tus necesidades"
              title="Análisis personalizado"
            />
          </div>
        </div>
        <div className="RightBlock">
          <img src={SOUTHBRIDGE_ICON} />
          <div className={classes.parrafo}>
            Tenemos un convenio con <strong>SouthBridge</strong> para una tarifa
            preferencial. Sino, puedes pedir un análisis de diferentes
            alternativas.
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResponsabilyPet;
