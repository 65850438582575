import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./TypeSure.styles";
import useGeneralStyles from "@components/Services/styles";
import { BirthdateInput } from "@components/Inputs";
import { ButtonPrimary } from "@components/Reusables";
import { useForm } from "@hooks";

interface ResponsiblePetProps {
  className?: string;
  setStepContinue: () => void;
  typeSecure: number;
  onSend: () => void;
  setFinishSave: (v: any) => void;
}

const TypeSure = ({
  className,
  setStepContinue,
  typeSecure,
  onSend,
  setFinishSave,
}: ResponsiblePetProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [step, setStep] = useState<Date>();
  const [form] = useForm();

  let StepComponent;

  switch (typeSecure) {
    case 0:
      StepComponent = (
        <div className={`${classesGeneral.secondary} ${classes.width}`}>
          {isMobile && (
            <div className={classesGeneral.principalTitle}>Solo de vida</div>
          )}
          Un <span>seguro de VIDA</span> protege a tus seres queridos en casos
          fatales. Entrega un <span>monto</span> para ellos{" "}
          <span> según el seguro</span> que contrates
        </div>
      );
      break;

    case 1:
      StepComponent = (
        <div className={`${classesGeneral.secondary} ${classes.width}`}>
          {" "}
          {isMobile && (
            <div className={classesGeneral.principalTitle}>Vida con ahorro</div>
          )}
          Este seguro entrega protección a tus seres queridos en casos fatales y{" "}
          <span> permite ahorrar todos los meses.</span> <br />
          En caso fatal, tus seres queridos reciben el{" "}
          <span> monto del seguro más los ahorros.</span>
        </div>
      );
      break;

    case 2:
      StepComponent = (
        <div className={`${classesGeneral.secondary} ${classes.width}`}>
          {" "}
          {isMobile && (
            <div className={classesGeneral.principalTitle}>
              Vida con protección
            </div>
          )}
          Este seguro te protege a ti y tus seres queridos, en caso de
          <span> accidentes invalidantes o fatales.</span> Recibes un monto
          acorde al seguro contratado.
        </div>
      );
      break;

    case 3:
      StepComponent = (
        <div className={`${classesGeneral.secondary} ${classes.width}`}>
          {" "}
          {isMobile && (
            <div className={classesGeneral.principalTitle}>Vida con APV</div>
          )}
          Este seguro entrega cobertura a tus seres queridos en casos fatales y
          permite
          <span> ahorrar con los beneficios del APV.</span>
        </div>
      );
      break;
    case 4:
      StepComponent = (
        <div className={`${classesGeneral.secondary} ${classes.center}`}>
          {" "}
          <div className={classesGeneral.secondary}>
            Para analizar los seguros, necesitamos tu fecha de nacimiento
          </div>
          <br />
          <div>
            <BirthdateInput
              name=""
              onChange={(e) => {
                setStep(e.target.value as Date);
              }}
              value={step}
            />
          </div>
          <div
            className={`${classesGeneral.containerButtons} ${classes.centerButton}`}
          >
            <ButtonPrimary
              text="Enviar"
              disabled={!step}
              onClick={() => {
                form.sure = {
                  ...form.sure,
                  "Fecha de nacimiento": step,
                };
                setFinishSave({
                  Notification2448: false,
                  BallCharge: true,
                });
                onSend();
              }}
              noDegraded
            />
          </div>
        </div>
      );
      break;
  }

  return <>{StepComponent}</>;
};
export default TypeSure;
