import React from "react";
import { CommonSelectorProps, Select } from "@components";

export const MinimumCovered: React.FC<CommonSelectorProps> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-gender-selector`}
      name={name || "reazonContact"}
      label={label || ""}
      options={[
        {
          name: "60%",
          value: "60%",
        },
        {
          name: "60%",
          value: "60%",
        },
        {
          name: "60%",
          value: "60%",
        },
        {
          name: "60%",
          value: "60%",
        },
        {
          name: "60%",
          value: "60%",
        },
      ]}
      {...props}
    />
  );
};
