import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./Life.styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import useGeneralStyles from "@components/Services/styles";
import HeaderServices from "@components/Services/Layout/Header/Header";
import TypeSure from "./TypeSure/TypeSure";
import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";
import { useForm } from "@hooks";

interface ResponsiblePetProps {
  className?: string;
  setStepContinue: () => void;
  onSend: () => void;
  setFinishSave: (v: any) => void;
}

const Life = ({
  className,
  setStepContinue,
  onSend,
  setFinishSave,
}: ResponsiblePetProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [step, setStep] = useState(-1);
  const [typeSecure, setTypeSecure] = useState(-1);
  const [nameStep, setNameStep] = useState<string>("");
  const [form] = useForm();
  let StepComponent;

  switch (step) {
    case -1:
      StepComponent = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Vida"]}
            onBackClick={() => {
              setStepContinue();
            }}
          />
          <div className={classes.container}>
            <div className={classesGeneral.secondary}>
              Un seguro de vida para proteger a los que más quieres
            </div>
            <div className={classesGeneral.principalTitle}>
              {" "}
              ¿Qué tipo de seguro buscas?
            </div>
            <div className={classes.containerList}>
              {list.map((item) => (
                <ServiceButton
                  onClick={() => {
                    setTypeSecure(item.id);
                    setStep(0);
                    setNameStep(item.title);
                  }}
                  name={item.title}
                  key={item.id}
                />
              ))}
            </div>
          </div>
        </>
      );
      break;
    case 0:
      StepComponent = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Vida", nameStep]}
            onBackClick={() => {
              setStep(-1);
            }}
          />
          <div className={classes.container}>
            <div>
              <TypeSure
                setStepContinue={setStepContinue}
                typeSecure={typeSecure}
                onSend={onSend}
                setFinishSave={setFinishSave}
              />{" "}
              {typeSecure !== 4 && (
                <div
                  className={`${classesGeneral.containerButtons} ${classes.end}`}
                >
                  <ButtonPrimary
                    text="Quiero este"
                    onClick={() => {
                      form.sure = {
                        ...form.sure,
                        "Tipo de seguro": nameStep,
                      };
                      setFinishSave({
                        Notification2448: false,
                        BallCharge: true,
                      });
                      onSend();
                    }}
                    noDegraded
                  />
                  <ButtonOutlined
                    text="Ver otro seguro"
                    onClick={() => setStep(-1)}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      );
      break;
  }

  return <>{StepComponent}</>;
};
export default Life;

const list = [
  {
    id: 0,
    title: "Solo de vida",
  },
  {
    id: 1,
    title: "Vida con ahorro",
  },
  {
    id: 2,
    title: "Vida con protección",
  },
  {
    id: 3,
    title: "Vida con APV",
  },
  {
    id: 4,
    title: "No sé cuál necesito",
  },
];
