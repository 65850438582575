import React, { useState } from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import useGeneralStyles from "@components/Services/styles";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { BirthdateInput, TextInput } from "@components/Inputs";
import { ButtonPrimary } from "@components/Reusables";
import { DUAL_BALL } from "@images";

interface ResponsiblePetProps {
  className?: string;
}

const BallCharge = ({ className }: ResponsiblePetProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [step, setStep] = useState(-1);

  return (
    <div className={classes.container}>
      <img className={classes.center} src={DUAL_BALL} />
      <div className={`${classes.center} ${classes.perfect}`}>¡PERFECTO!</div>
      <div className={`${classes.width} ${classes.center}`}>
        Te estamos re dirigiendo al al seguro para que lo veas en detalle
      </div>
    </div>
  );
};
export default BallCharge;

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    background: "#008296",
    height: "100vh",
    color: "white",
    fontSize: "1rem",
    fontWeight: 300,
    fontFamily: "Montserrat",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    justifyContent: "center",
    alignItems: "center",
  },
  perfect: {
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  center: {
    width: "17rem",
    textAlign: "center",
    margin: "0 auto",
  },
}));
