import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./TypeSecurity.style";
import HeaderServices from "@components/Services/Layout/Header/Header";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import useGeneralStyles from "@components/Services/styles";
import FormPet from "../FormPet/FormPet";
import ResponsabilyPet from "../ResponsabilyPet/ResponsabilyPet";
import { useForm } from "@hooks";

interface YouSeekProps {
  className?: string;
  setStepContinue: (value: number) => void;
  setFinishSave: (v: any) => void;
  onSend: () => void;
}
const TypeSecurityPets = ({
  className,
  setStepContinue,
  setFinishSave,
  onSend,
}: YouSeekProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [step, setStep] = useState(-1);
  const [typeSecure, setTypeSecure] = useState(false);
  let StepSearchingSelect;
  const [form] = useForm({});

  switch (step) {
    case -1:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Mascotas"]}
            onBackClick={() => {
              setStepContinue(-1);
            }}
          />
          <div className={`${className || ""} ${classes.container}`}>
            <div className={classes.title}>TIPO DE SEGURO</div>
            <section className={classes.containerList}>
              {List.map((item) => (
                <ServiceButton
                  onClick={() => {
                    setStep(item.id);
                    form.sure = {
                      "Tipo de seguro para mascota": item.title,
                    };
                  }}
                  key={item.id}
                  name={item.name}
                  title={item.title}
                />
              ))}
            </section>
            <div className={`${classesGeneral.title} ${classes.centerEndText}`}>
              ¡Los más regalones de la casa también deben estar protegidos!
            </div>
          </div>
        </>
      );
      break;
    case 0:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Mascotas", "Mascota Responsable"]}
            onBackClick={() => {
              setStep(-1);
            }}
          />
          <FormPet
            setStep={setStep}
            onClick={() => {
              setTypeSecure(false);
            }}
            setFinishSave={setFinishSave}
            onSend={onSend}
          />
        </>
      );
      break;
    case 1:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Mascotas", "Salud"]}
            onBackClick={() => {
              setStep(-1);
            }}
          />
          <FormPet
            setStep={setStep}
            onClick={() => {
              setTypeSecure(true);
            }}
            setFinishSave={setFinishSave}
            onSend={onSend}
          />
        </>
      );
      break;
    case 2:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={
              typeSecure
                ? ["Mascotas", "Salud"]
                : ["Mascotas", "Mascota Responsable"]
            }
            onBackClick={() => {
              setStep(-1);
            }}
          />
          <ResponsabilyPet
            setStep={setStep}
            onClick={() => {
              setTypeSecure(true);
            }}
          />
        </>
      );
      break;
  }
  return <>{StepSearchingSelect}</>;
};
export default TypeSecurityPets;

const List = [
  { id: 0, name: "Protección de Resp. Civil", title: "Mascota responsable" },
  {
    id: 1,
    name: "Consulta, Urgencia, Medicamentos, Resp. Civil",
    title: "Salud",
  },
];
