import React from "react";
import { CommonSelectorProps, Select } from "@components";

export const TypeAntiquity: React.FC<CommonSelectorProps> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-gender-selector`}
      name={name || "reazonContact"}
      label={label || "Antigüedad"}
      options={[
        {
          name: "Menos de 15 años",
          value: "Menos de 15 años",
        },
        {
          name: "Entre 15 y 29 años",
          value: "Entre 15 y 29 años",
        },
        {
          name: "Más de 29 años",
          value: "Más de 29 años",
        },
      ]}
      {...props}
    />
  );
};
