import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: "100%",
      height: "90vh",
      margin: "2rem auto 0",
      justifyContent: "center",
    },
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
  },
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "2.03rem",
  },
  centerLastText: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
    gap: "2.03rem",
  },
  end: {
    justifyContent: "end",
    marginTop: "2rem",
    [theme.breakpoints.down(1300)]: {
      justifyContent: "center",
    },
  },
  selector: {
    width: "17rem",
    [theme.breakpoints.down(1300)]: {
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
      width: "100%",
      maxWidth: "20rem",
    },
  },
  containerFlex: {
    display: "flex",
    gap: "3rem",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "20rem",
      margin: "0 auto",
    },
  },
}));
export default useStyles;
