import React, { useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import useStyles from "./Basic.styles";
import useGeneralStyles from "@components/Services/styles";
import { BACK_ICON } from "images/GCP";
import { useForm } from "@hooks";
interface YouSeekProps {
  className?: string;
  onContinue: () => void;
}
const BasicHealth = ({ className, onContinue }: YouSeekProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();

  const [isRadio, setRadio] = useState(false);
  const [describe, setDescribe] = useState("");

  const [form] = useForm({});

  return (
    <>
      <div className={classes.container}>
        <div className={classes.containerFlex}>
          <div>
            <div className={classesGeneral.textLabel}>
              ¿Ya cuentas con un plan de Fonasa o Isapre?
            </div>
            <div className={classes.RadioGroup}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={true}
                onChange={() => {}}
              >
                <FormControlLabel
                  checked={isRadio}
                  value="fraction"
                  control={<Radio />}
                  label="Si"
                  onClick={() => setRadio(!isRadio)}
                />
                <FormControlLabel
                  value="all"
                  checked={!isRadio && true}
                  control={<Radio />}
                  onClick={() => setRadio(!isRadio)}
                  label={`No`}
                />
              </RadioGroup>
            </div>
          </div>{" "}
          <div>
            <div className={classesGeneral.textLabel}>
              ¿Qué plan tienes? mientras más detallado,
              <br /> mejor?
            </div>
            <textarea
              className={`${classes.styleSpace}`}
              value={describe}
              onChange={(e) => setDescribe(e.target.value)}
              placeholder="Text"
            />
          </div>
          {isMobile && (
            <div className={classes.positionArrow}>
              <img
                onClick={() => {
                  onContinue();
                  form.sure = {
                    ...form.sure,
                    "Planes detallados": describe,
                    "¿Cuentas con un plan de Fonasa?": isRadio ? "si" : "no",
                  };
                }}
                src={BACK_ICON}
              />
            </div>
          )}
        </div>
        {!isMobile && (
          <img
            className={classes.positionArrow}
            onClick={() => {
              onContinue();
              form.sure = {
                ...form.sure,
                "Planes detallados": describe,
                "¿Cuentas con un plan de Fonasa?": isRadio ? "si" : "no",
              };
            }}
            src={BACK_ICON}
          />
        )}
      </div>
    </>
  );
};
export default BasicHealth;
