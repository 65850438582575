import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./ForecastAndDate.styles";
import useGeneralStyles from "@components/Services/styles";
import { useForm } from "@hooks";
import { YesOrNoSelector } from "@components/Inputs/SingleSelectors/Services/YesOrNo";
import { BirthdateInput } from "@components/Inputs";
import { ButtonPrimary } from "@components/Reusables";
import { Forecast } from "@components/Inputs/SingleSelectors/Services/Forecast";

interface ResponsiblePetProps {
  className?: string;
  topTitle?: string;
  setFinishSave: (v: any) => void;
  onSend: () => void;
}

const ForecastAndDate = ({
  className,
  topTitle,
  setFinishSave,
  onSend,
}: ResponsiblePetProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [form] = useForm({});
  const [date, setDate] = useState<Date>();
  const [prevision, setPrivision] = useState<string>();

  const enabled = !(date && prevision);

  return (
    <>
      <div className={classes.container}>
        <div className="flex">
          <div>
            <div className={classesGeneral.textLabel}>Fecha de nacimiento</div>
            <BirthdateInput
              onChange={(e) => {
                setDate(e.target.value as Date);
              }}
              className={classes.selector}
              value={date}
            />
          </div>
          <div>
            <div className={classesGeneral.textLabel}>Tipo de Previsión</div>
            <Forecast
              className={classes.selector}
              value={prevision}
              onChange={(e) => {
                setPrivision(e.target.value as string);
              }}
            />
          </div>
        </div>
        <div className={`${classesGeneral.containerButtons} containerButton`}>
          <ButtonPrimary
            text="Enviar"
            disabled={enabled}
            onClick={() => {
              form.sure = {
                ...form.sure,
                "Fecha de nacimiento": date,
                "Tipo de Previsión": prevision,
              };
              setFinishSave({
                Notification2448: true,
                BallCharge: false,
              });
              onSend();
            }}
            noDegraded
          />
        </div>
      </div>
    </>
  );
};
export default ForecastAndDate;
