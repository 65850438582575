import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "2rem 2rem 3rem",
      height: "100vh",
    },
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "2.03rem",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "center",
    },
  },
}));
export default useStyles;
