import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./Complementary.style";
import useGeneralStyles from "@components/Services/styles";
import { BirthdateInput } from "@components/Inputs";
import { DestinationSelector } from "@components/Inputs/SingleSelectors/Services/Destination";
import { ButtonPrimary } from "@components/Reusables";
import { useForm } from "@hooks";
import { MinimumCovered } from "@components/Inputs/SingleSelectors/Services/MinimumCovered";
interface YouSeekProps {
  className?: string;
  onClick: () => void;
  setFinishSave: (a: any) => void;
  onSend: () => void;
}
const ComplementaryHealth = ({
  className,
  onClick,
  setFinishSave,
  onSend,
}: YouSeekProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [form] = useForm();
  const [mini, setMini] = useState<string>();
  const [date, setDate] = useState<Date>();

  const enabled = !(mini && date);

  return (
    <div className={classes.container}>
      <div className={classes.containerFlex}>
        <div>
          <div className={classesGeneral.textLabel}>
            Mínimo que te gustaría estar cubierto
          </div>
          <MinimumCovered
            className={classes.selector}
            onChange={(e) => {
              setMini(e.target.value as string);
            }}
            value={mini}
          />
        </div>
        <div>
          <div className={classesGeneral.textLabel}>Fecha de nacimiento</div>
          <BirthdateInput
            className={classes.selector}
            onChange={(e) => {
              setDate(e.target.value as Date);
            }}
            value={date}
          />
        </div>
      </div>
      <div className={`${classesGeneral.containerButtons} ${classes.end}`}>
        <ButtonPrimary
          noDegraded
          disabled={enabled}
          text="Enviar"
          onClick={() => {
            form.sure = {
              ...form.sure,
              "Mínimo que te gustaría estar cubierto": mini,
              "Fecha de nacimiento": date,
            };
            onClick();
            setFinishSave({
              Notification2448: true,
              BallCharge: false,
            });

            onSend();
          }}
        />
      </div>
    </div>
  );
};
export default ComplementaryHealth;
