import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto 0",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 4rem 5rem",
    "& .containerFlex": {
      display: "flex",
      gap: "8rem",
      [theme.breakpoints.down(1240)]: {
        flexDirection: "column-reverse",
        gap: "2rem",
        width: 330,
        margin: "0 auto",
      },
    },
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "2rem 2rem 3rem",
      height: "100vh",
      "& >div": {
        width: 330,
        margin: "0 auto",
        fontSize: "0.75rem",
      },
    },
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  containerList: {
    justifyContent: "space-between",
    "& ul": {
      lineHeight: 0.75,
      margin: "3rem auto",
      [theme.breakpoints.down(1240)]: {
        margin: "1rem auto",
        fontSize: "0.75rem",
      },
    },
    "& li": {
      listStyle: "inside",
      [theme.breakpoints.down(1240)]: {
        fontSize: "0.75rem",
        fontWeight: 300,
      },
    },
  },
  buttonPrimary: {
    width: "14rem",
    height: "4.8rem",
    fontFamily: "Nunito",
    fontSize: "1.2rem",
    fontWeight: 300,
  },
  parrafo: {
    color: "#7d7c7c",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    paddingTop: "1.75rem",
    width: "22rem",
  },
  containerButtons: {
    display: "flex",
    gap: "3.5rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column-reverse",
      gap: "1rem",
    },
  },
}));
export default useStyles;
