import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "67.5rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "3rem 4rem 5rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "2rem 2rem 3rem",
      height: "100vh",
    },
    "& .containerFlex": {
      display: "flex",
      gap: "8rem",
    },
    "& .containertxt": {
      display: "flex",
      gap: "1rem",
      alignItems: "inherit",
      "& span": {
        marginTop: 3,
      },
    },
    "& .divFlex": {
      display: "flex",
      gap: "3.5rem",
      [theme.breakpoints.down(1240)]: {
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  input: {
    display: "flex",
    flexDirection: "column",
    color: "#6b6b6b",
    fontSize: "0.75rem",
    fontFamily: "Montserrat",
    fontWeight: 600,
    marginBottom: "0.5rem",
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: "287px",
      margin: "0 auto",
    },
  },
  selector: {
    width: "17rem",
  },
  containerInputs: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3rem",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "center",
    },
  },
  containerButtons: {
    justifyContent: "end",
    marginTop: "2rem",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "center",
      marginTop: "4rem",
    },
  },
  selectorMinimized: {
    width: "8rem",
  },
}));
export default useStyles;
