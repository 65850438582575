import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    padding: "2.3rem 6rem 5rem",
    [theme.breakpoints.down(1300)]: {
      margin: "2rem auto 0",
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    "& .secondary": {
      [theme.breakpoints.down(1300)]: {
        fontSize: "0.75rem",
        width: "18rem",
        margin: "0 auto",
      },
    },
    "& .containerFlex": {
      display: "flex",
      gap: "8rem",
      [theme.breakpoints.down(1300)]: {
        flexDirection: "column-reverse",
        gap: "1rem",
        fontSize: "0.75rem",
      },
    },
    "& .RightBlock": {
      marginTop: "1rem",
      "& img": {
        [theme.breakpoints.down(1300)]: {
          margin: "0 auto",
        },
      },
    },
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: "1.5rem",
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  containerList: {
    justifyContent: "space-between",
    "& ul": {
      lineHeight: 0.75,
      margin: "3rem auto",
    },
    "& li": {
      listStyle: "inside",
      [theme.breakpoints.down(1300)]: {
        fontSize: "0.75rem",
      },
    },
  },
  buttonPrimary: {
    width: "14rem",
    height: "4.8rem",
    fontFamily: "Nunito",
    fontSize: "1.2rem",
    fontWeight: 300,
  },
  parrafo: {
    color: "#7d7c7c",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    paddingTop: "1.75rem",
    width: "22rem",
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: "18rem",
      margin: "0 auto",
    },
  },
  containerButton: {
    display: "grid",
    gap: "2.5rem",
    [theme.breakpoints.down(1300)]: {
      gap: "1rem",
    },
  },
}));
export default useStyles;
